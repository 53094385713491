import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"

const FourCols = props => {
  return (
    <>
     <a id={props.name} />
      <Container className={props.class} id={props.id}>
        
        <Row className="team-row">
        <Col xs={6} lg={3}>
        <div  className="team-box" className={props.class1}>
            <img src={props.image1} alt={props.alt1}/>
            
            </div>
            <h3 className="team-name">{props.name1}</h3>
            <p className="team-role">{props.role1}</p>
          </Col>
          <Col xs={6} lg={3}>
            <div  className="team-box" className={props.class2}>
                <img src={props.image2} alt={props.alt2}/>
                
            </div>
            <h3 className="team-name">{props.name2}</h3>
            <p className="team-role">{props.role2}</p>
          </Col>
           <Col xs={6} lg={3}>
            <div  className="team-box" className={props.class3}>
                <img src={props.image3} alt={props.alt3}/>
            
            </div>
            <h3 className="team-name">{props.name3}</h3>
            <p className="team-role">{props.role3}</p>
          </Col>
          <Col xs={6} lg={3}>
            <div  className="team-box" className={props.class4}>
                <img src={props.image4} alt={props.alt4}/>
            
            </div>
            <h3 className="team-name">{props.name4}</h3>
            <p className="team-role">{props.role4}</p>
          </Col>
         
        </Row>
        
      </Container>
    </>
  )
}

export default FourCols
