import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"


import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"


const Hero = props => {
  

  return (
    <>
      <Container className={props.class} style={{position:"relative", zIndex:"0"}} >
        <Row>
          <Col xs={12} lg={6} className="text-container">
            <h1>
              {props.title}
            </h1>
            <p>
              {props.text}
            </p>
            <Link to="https://dashboard.paperhandsnft.com/dashboard"> <Button>
            Dashboard
          </Button></Link>

          
          </Col>
          <Col xs={12} lg={6} className="jumbo-right-image">
            <div className="gatsby-image-wrapper">
          <img src={props.image} alt={props.alt} /> 
          </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Hero
