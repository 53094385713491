import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import {
  Container,
  Row,
  Col,
  Accordion,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"

const FAQs = props => {
  return (
    <>
      <Container
        className="jumbo-section text-left"
        className={props.class}
        id={props.id}
      >
        <Row>
          <Col xs={12} lg={8} className="text-container">
            <a name={props.name}></a>
            <h2>{props.title}</h2>
            <p>{props.text}</p>
            <Accordion>
  <Accordion.Item eventKey="0">
    <Accordion.Header> What kind of game is this?</Accordion.Header>
    <Accordion.Body>
    The world's first Paint-2-Earn game. Join the discord to learn about the details.

    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Wen mint?
</Accordion.Header>
    <Accordion.Body>
    Sunday, March 20th.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header>What is the mint price?
</Accordion.Header>
    <Accordion.Body>
    0.08 ETH.

    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3">
    <Accordion.Header>How many can we mint?
</Accordion.Header>
    <Accordion.Body>
    2 on Paperlist. 2 on Public.

    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4">
    <Accordion.Header> Where can I buy Paperhands?

</Accordion.Header>
    <Accordion.Body>
    Mint will occur on this website. After mint you will be able to buy off secondary (OpenSea or LooksRare).


    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="5">
    <Accordion.Header> Can I mint from the contract?

</Accordion.Header>
    <Accordion.Body>
    For public sale, yes. For whitelist sale, no. You will need to mint from our website.


    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="6">
    <Accordion.Header>  Is it the Ethereum blockchain?

</Accordion.Header>
    <Accordion.Body>
    Yes.


    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="7">
    <Accordion.Header>  How many PaperHands NFTs are there?

</Accordion.Header>
    <Accordion.Body>
    Collection Size: 3,000.



    </Accordion.Body>
  </Accordion.Item>
</Accordion>
          </Col>
          <Col xs={12} lg={4} className="jumbo-image">
          <div className="gatsby-image-wrapper"><img src={props.image} alt={props.alt} /></div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FAQs
