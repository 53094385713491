import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"

const TwoCols = props => {
  return (
    <>
      <Container
        className="jumbo-section text-left"
        className={props.class}
        id={props.id}
      >
        <Row style={{position: "relative"}}>
            <div className="floating-img">
                <img src={props.floatingImg}/>
            </div>
          <Col xs={12} lg={6} className="text-container">
            <a name={props.name}></a>
            <h2 className="roadmap-date">{props.date}</h2>
            <img src={props.lineImg} className="line-img"/>
            <h2>{props.title}</h2>
            <p>{props.text}</p>
          </Col>
          <Col xs={12} lg={6} className="jumbo-image">
          <div className="gatsby-image-wrapper"><img src={props.image} alt={props.alt} /></div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TwoCols
