import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"

const DiamondHands = props => {
  return (
    <>
      <Container
        className="jumbo-section text-left"
        className={props.class}
        id={props.id}
        style={{padding: "0"}}
      >
        <Row style={{position: "relative"}}>
            <div className="floating-img">
                <img src={props.floatingImg}/>
            </div>
          <Col xs={12} lg={6} className="text-container">
            <a name={props.name}></a>
            <img src={props.lineImg} className="line-img"/>
            <h2  className="diamond-header">The <span>Game</span></h2>
          <ul className="the-game">
            <li>Competitive play-to-earn game.</li>
            <li>Guess what is being drawn by the “artist”.</li>
            <li>Earn $PPR rewards every time you play.</li>
            <li>Competitive Tournaments & Events.</li>
            <li>Upgrade Your PaperHands NFT with $PPR.</li>
            </ul>
          </Col>
          <Col xs={12} lg={6} className="jumbo-image">
          <div className="gatsby-image-wrapper"><img src={props.image} alt={props.alt} /></div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default DiamondHands
